import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-isha-ambassador',
  templateUrl: './isha-ambassador.component.html',
  styleUrls: ['isha-ambassador.component.scss']
})
export class IshaAmbassadorComponent {
  @Input() program: Program;
  constructor() {
  }
}
